const trustImages = [
  {
    name: 'Trust 1',
    src: 'assets/img/trust-1.png',
  },
  {
    name: 'Trust 2',
    src: 'assets/img/trust-2.png',
  },
  {
    name: 'Trust 3',
    src: 'assets/img/trust-3.png',
  },
  {
    name: 'Trust 4',
    src: 'assets/img/trust-4.png',
  },
  {
    name: 'Trust 5',
    src: 'assets/img/trust-5.png',
  },
  {
    name: 'Trust 1',
    src: 'assets/img/trust-1.png',
  },
  {
    name: 'Trust 2',
    src: 'assets/img/trust-2.png',
  },
  {
    name: 'Trust 3',
    src: 'assets/img/trust-3.png',
  },
  {
    name: 'Trust 4',
    src: 'assets/img/trust-4.png',
  },
  {
    name: 'Trust 5',
    src: 'assets/img/trust-5.png',
  },
  
];

export default trustImages;
